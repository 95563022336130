import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
    return (
        <section>
            <div className="rows inner_banner inner_banner_4">
                <div className="container">
                    <h2><span>Brazil &amp; Argentina -</span> Family Package</h2>
                    <ul>
                        <li>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li><FontAwesomeIcon icon={faAngleRight} /></li>
                        <li>
                            <NavLink to="/detail" className="bread-acti">Brazil &amp; Argentina</NavLink>
                        </li>
                    </ul>
                    <p>Book travel packages and enjoy your holidays with distinctive experience</p>
                </div>
            </div>
        </section>
    );
};

export default Banner;