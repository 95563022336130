import React from 'react';
import { NavLink } from 'react-router-dom';

const Events = () => {
    return (
        <section>
            <div className="rows tb-space">
                <div className="container events events-1" id="inner-page-title">
                    <div className="spe-title">
                        <h2>Top <span>Events</span> in this month</h2>
                        <div className="title-line">
                            <div className="tl-1"></div>
                            <div className="tl-2"></div>
                            <div className="tl-3"></div>
                        </div>
                        <p>World's leading tour and travels Booking website,Over 30,000 packages worldwide. Book travel packages and enjoy your holidays with distinctive experience</p>
                    </div>
                    <input type="text" id="myInput" placeholder="Search Event Name.." title="Type in a name" />
                    <table id="myTable">
                        <tbody>
                            <tr>
                                <th>#</th>
                                <th>Event Name</th>
                                <th className="e_h1">Date</th>
                                <th className="e_h1">Time</th>
                                <th className="e_h1">Location</th>
                                <th>Book</th>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>1</td>
                                <td><img src="../iplace-1.jpg" alt="" /><NavLink to="#" className="events-title">Taj Mahal,Agra, India</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">Australia</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>2</td>
                                <td><img src="../iplace-2.jpg" alt="" /><NavLink to="#" className="events-title">Salesforce Summer, Dubai</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">Dubai</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>3</td>
                                <td><img src="../iplace-3.jpg" alt="" /><NavLink to="#" className="events-title">God Towers, TOKYO, JAPAN</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">JAPAN</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>4</td>
                                <td><img src="../iplace-4.jpg" alt="" /><NavLink to="#" className="events-title">TOUR DE ROMANDIE, Switzerland</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">Switzerland</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>5</td>
                                <td><img src="../iplace-5.jpg" alt="" /><NavLink to="#" className="events-title">TOUR DE POLOGNE, Poland</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">Poland</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>6</td>
                                <td><img src="../iplace-6.jpg" alt="" /><NavLink to="#" className="events-title">Future of Marketing,Sydney, Australia</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">Australia</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>7</td>
                                <td><img src="../iplace-7.jpg" alt="" /><NavLink to="#" className="events-title">Eiffel Tower, Paris</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">France</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>8</td>
                                <td><img src="../iplace-8.jpg" alt="" /><NavLink to="#" className="events-title">PARIS - ROUBAIX, England</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">England</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>9</td>
                                <td><img src="../iplace-9.jpg" alt="" /><NavLink to="#" className="events-title">Dubai Beach Resort, Dubai</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">Dubai</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                            <tr className='zoomOutTr'>
                                <td>10</td>
                                <td><img src="../iplace-4.jpg" alt="" /><NavLink to="#" className="events-title">TOUR DE POLOGNE, Poland</NavLink> </td>
                                <td className="e_h1">16.12.2016</td>
                                <td className="e_h1">10.00 PM</td>
                                <td className="e_h1">Poland</td>
                                <td><NavLink to="/detail" className="link-btn">Book Now</NavLink> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Events;