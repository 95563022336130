import React from 'react';
import { NavLink } from 'react-router-dom';

const Sightseeing = () => {
    return (
        <section>
            <div className="rows pla pad-bot-redu tb-space">
                <div className="pla1 p-home container">
                    <div className="spe-title spe-title-1">
                        <h2>Top <span>Sightseeing</span> in this month</h2>
                        <div className="title-line">
                            <div className="tl-1"></div>
                            <div className="tl-2"></div>
                            <div className="tl-3"></div>
                        </div>
                        <p>World's leading tour and travels Booking website,Over 30,000 packages worldwide. Book travel packages and enjoy your holidays with distinctive experience</p>
                    </div>
                    <div className="popu-places-home">
                        <div className="col-md-6 col-sm-6 col-xs-12 place">
                            <div className="col-md-6 col-sm-12 col-xs-12"> <img src="../place2.jpg" alt="" /> </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <h3><span>Honeymoon Package</span> 7 Days / 6 Nights</h3>
                                <p>lorem ipsum simplelorem ipsum simplelorem ipsum simplelorem ipsum simple</p> <NavLink to="/detail" className="link-btn">more info</NavLink> </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 place">
                            <div className="col-md-6 col-sm-12 col-xs-12"> <img src="../place1.jpg" alt="" /> </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <h3><span>Family package</span> 14 Days / 13 Nights</h3>
                                <p>lorem ipsum simplelorem ipsum simplelorem ipsum simplelorem ipsum simple</p> <NavLink to="/detail" className="link-btn">more info</NavLink> </div>
                        </div>
                    </div>
                    <div className="popu-places-home">
                        <div className="col-md-6 col-sm-6 col-xs-12 place">
                            <div className="col-md-6 col-sm-12 col-xs-12"> <img src="../place3.jpg" alt="" /> </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <h3><span>Weekend Package </span> 3 Days / 2 Nights</h3>
                                <p>lorem ipsum simplelorem ipsum simplelorem ipsum simplelorem ipsum simple</p> <NavLink to="/detail" className="link-btn">more info</NavLink> </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 place">
                            <div className="col-md-6 col-sm-12 col-xs-12"> <img src="../place4.jpg" alt="" /> </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <h3><span>Group Package</span> 10 Days / 9 Nights</h3>
                                <p>lorem ipsum simplelorem ipsum simplelorem ipsum simplelorem ipsum simple</p> <NavLink to="/detail" className="link-btn">more info</NavLink> </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sightseeing;