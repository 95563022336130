
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGooglePlusG, faTwitter, faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const IconLeft = () => {
    return (
        <section>
            <div className="icon-float">
                <ul>
                    <li><NavLink to="#" className="sh">10M <br/> Share</NavLink> </li>
                    <li><NavLink to="#" className="fb1"><FontAwesomeIcon style={{ color: '#fff', alignItems: 'center' }} icon={faFacebookF} /></NavLink> </li>
                    <li><NavLink to="#" className="gp1"><FontAwesomeIcon style={{ color: '#fff', alignItems: 'center' }} icon={faGooglePlusG} /></NavLink> </li>
                    <li><NavLink to="#" className="tw1"><FontAwesomeIcon style={{ color: '#fff', alignItems: 'center' }} icon={faTwitter} /></NavLink> </li>
                    <li><NavLink to="#" className="li1"><FontAwesomeIcon style={{ color: '#fff', alignItems: 'center' }} icon={faLinkedinIn} /></NavLink> </li>
                    <li><NavLink to="#" className="wa1"><FontAwesomeIcon style={{ color: '#fff', alignItems: 'center' }} icon={faWhatsapp} /></NavLink> </li>
                    <li><NavLink to="#" className="sh1"><FontAwesomeIcon style={{ color: '#fff', alignItems: 'center' }} icon={faEnvelopeOpen} /></NavLink> </li>
                </ul>
            </div>
        </section>
    )
}

export default IconLeft;