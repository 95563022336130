import React from 'react';
import { NavLink } from 'react-router-dom';

const TourzSearch = () => {
    return (
        <section>
            <div className="tourz-search">
                <div className="container">
                    <div className="row">
                        <div className="tourz-search-1">
                            <h1>Plan Your Travel Now!</h1>
                            <p>Experience the various exciting tour and travel packages and Make hotel reservations, find vacation packages, search cheap hotels and events</p>
                            <form className="tourz-search-form">
                                <div className="input-field">
                                    <input type="text" id="select-city" className="autocomplete" placeholder='Enter city' />
                                    <ul className="autocomplete-content dropdown-content"></ul></div>
                                <div className="input-field">
                                    <input type="text" id="select-search" className="autocomplete" placeholder='Search over a million tour and travels, sight seeings, hotels and more' />
                                    <ul className="autocomplete-content dropdown-content"></ul></div>
                                <div className="input-field">
                                    <i className="waves-effect waves-light tourz-sear-btn waves-input-wrapper"><input type="submit" value="search" className="waves-button-input" /></i> </div>
                            </form>
                            <div className="tourz-hom-ser">
                                <ul>
                                    <li>
                                        <NavLink to="#" className="waves-effect waves-light btn-large tourz-pop-ser-btn wow fadeInUp animated" data-wow-duration="0.5s" style={{ visibility: "visible" }}>
                                            <div className='hoverZoom'><figure><img src="../2.png" alt="" /> Tour</figure></div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#" className="waves-effect waves-light btn-large tourz-pop-ser-btn wow fadeInUp animated" data-wow-duration="1s" style={{ visibility: "visible" }}>
                                            <div className='hoverZoom'><figure><img src="../31.png" alt="" /> Flight</figure></div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#" className="waves-effect waves-light btn-large tourz-pop-ser-btn wow fadeInUp animated" data-wow-duration="1.5s" style={{ visibility: "visible" }}>
                                            <div className='hoverZoom'><figure><img src="../30.png" alt="" /> Car Rentals</figure></div>
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="#" className="waves-effect waves-light btn-large tourz-pop-ser-btn wow fadeInUp animated" data-wow-duration="2s" style={{ visibility: "visible" }}>
                                            <div className='hoverZoom'><figure><img src="../1.png" alt="" /> Hotel</figure></div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TourzSearch;