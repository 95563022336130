import React from 'react';

const Footer2 = () => {
    return (
        <section>
            <div className="rows copy">
                <div className="container">
                    <p>Copyrights © 2022 Tyler Test. All Rights Reserved</p>
                </div>
            </div>
        </section>
    );
};

export default Footer2;