import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
    return (
        <section>
            <div className="rows inner_banner inner_banner_1">
                <div className="container">
                    <h2><span>About us -</span> World's leading tour Booking</h2>
                    <ul>
                        <li>
                            <NavLink to="/" exact={true}>Home</NavLink>
                        </li>
                        <li><FontAwesomeIcon icon={faAngleRight} /></li>
                        <li>
                            <NavLink to="/about" className="bread-acti">About Us</NavLink>
                        </li>
                    </ul>
                    <p>Book travel packages and enjoy your holidays with distinctive experience</p>
                </div>
            </div>
        </section>
    );
};

export default Banner;