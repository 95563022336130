import React from 'react';
import { NavLink } from 'react-router-dom';

const Tips = () => {
    return (
        <section>
            <div className="rows tips tips-home tb-space home_title">
                <div className="container tips_1">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <h3>Tips Before Travel</h3>
                        <div className="tips_left tips_left_1">
                            <h5>Bring copies of your passport</h5>
                            <p>Aliquam pretium id justo eget tristique. Aenean feugiat vestibulum blandit.</p>
                        </div>
                        <div className="tips_left tips_left_2">
                            <h5>Register with your embassy</h5>
                            <p>Mauris efficitur, ante sit amet rhoncus malesuada, orci justo sollicitudin.</p>
                        </div>
                        <div className="tips_left tips_left_3">
                            <h5>Always have local cash</h5>
                            <p>Donec et placerat ante. Etiam et velit in massa. </p>
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-6 col-xs-12 testi-2">
                        <h3>Customer Testimonials</h3>
                        <div className="testi">
                            <h4>John William</h4>
                            <p>Ut sed sem quis magna ultricies lacinia et sed tortor. Ut non tincidunt nisi, non elementum lorem. Aliquam gravida sodales</p> <address>Illinois, United States of America</address> </div>
                        <h3>Arrangement &amp; Helps</h3>
                        <div className="arrange">
                            <ul>
                                <li>
                                    <NavLink to="#"><img src="../Location-Manager.png" alt="" /> </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#"><img src="../Private-Guide.png" alt="" /> </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#"><img src="../Arrangements.png" alt="" /> </NavLink>
                                </li>
                                <li>
                                    <NavLink to="#"><img src="../Events-Activities.png" alt="" /> </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tips;