import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";

const Qoute = () => {
    return (
        <section>
            <div className="ho-popu tb-space pad-bot-redu">
                <div className="rows container">
                    <div className="spe-title">
                        <h2>Top <span>Branding</span> for this month</h2>
                        <div className="title-line">
                            <div className="tl-1"></div>
                            <div className="tl-2"></div>
                            <div className="tl-3"></div>
                        </div>
                        <p>World's leading tour and travels Booking website,Over 30,000 packages worldwide. Book travel packages and enjoy your holidays with distinctive experience</p>
                    </div>
                    <div className="ho-popu-bod">
                        <div className="col-md-4">
                            <div className="hot-page2-hom-pre-head">
                                <h4>Top Branding <span>Hotels</span></h4>
                            </div>
                            <div className="hot-page2-hom-pre">
                                <ul>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../hotels/1.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Taaj Club House</h5> <span>City: illunois, United States</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>4.5</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../hotels/2.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Universal luxury Grand Hotel</h5> <span>City: Rio,Brazil</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>4.2</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../hotels/3.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Barcelona Grand Pales</h5> <span>City: Chennai,India</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>5.0</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../hotels/4.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Lake Palace view Hotel</h5> <span>City: Beijing,China</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>2.5</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../hotels/8.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>First Class Grandd Hotel</h5> <span>City: Berlin,Germany</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>4.0</span> </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="hot-page2-hom-pre-head">
                                <h4>Top Branding <span>Packages</span></h4>
                            </div>
                            <div className="hot-page2-hom-pre">
                                <ul>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../trends/1.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Family Package Luxury</h5> <span>Duration: 7 Days and 6 Nights</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>4.1</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../trends/2.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Honeymoon Package Luxury</h5> <span>Duration: 14 Days and 13 Nights</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>4.4</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../trends/3.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Group Package Luxury</h5> <span>Duration: 28 Days and 29 Nights</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>3.0</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../trends/4.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Regular Package Luxury</h5> <span>Duration: 12 Days and 11 Nights</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>3.5</span> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../trends/1.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Custom Package Luxury</h5> <span>Duration: 10 Days and 10 Nights</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <span>5.0</span> </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="hot-page2-hom-pre-head">
                                <h4>Top Branding <span>Reviewers</span></h4>
                            </div>
                            <div className="hot-page2-hom-pre">
                                <ul>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../reviewer/1.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Christopher</h5> <span>No of Reviews: 620, City: illunois</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <FontAwesomeIcon icon={faHandPointRight} style={{ color: '#f4364f' }} /> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../reviewer/2.png" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Matthew</h5> <span>No of Reviews: 48, City: Rio</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <FontAwesomeIcon icon={faHandPointRight} style={{ color: '#f4364f' }} /> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../reviewer/3.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Stephanie</h5> <span>No of Reviews: 560, City: Chennai</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <FontAwesomeIcon icon={faHandPointRight} style={{ color: '#f4364f' }} /> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../reviewer/4.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Robert</h5> <span>No of Reviews: 920, City: Beijing</span> </div>
                                            <div className="hot-page2-hom-pre-3"> <FontAwesomeIcon icon={faHandPointRight} style={{ color: '#f4364f' }} /> </div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="#">
                                            <div className="hot-page2-hom-pre-1"> <img src="../reviewer/5.jpg" alt="" /> </div>
                                            <div className="hot-page2-hom-pre-2">
                                                <h5>Danielle</h5> <span>No of Reviews: 768, City: Berlin</span> </div>
                                            <div className="hot-page2-hom-pre-3"><FontAwesomeIcon icon={faHandPointRight} style={{ color: '#f4364f' }} /> </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Qoute;