import React from 'react';
import { NavLink } from 'react-router-dom';

const Index = () => {
    return (
        <section className="hot-page2-alp hot-page2-pa-sp-top">
            <div className="container d-none d-lg-block" style={{ marginTop: '-80px', marginBottom: '30px' }}>
                <div className="" style={{ background: '#205682', borderRadius: '20px', zIndex: 9, position: 'relative' }}>
                    <div className="container" style={{ width: '100%', padding: '0' }}>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                            <div className="content-form-sl1" style={{ padding: '10px 0 10px 0' }}>
                                <form className="form-horizontal" role="form" method="GET" action="/apitour">
                                    <div className="form-row form-search mt-2">
                                        <div className="col col-search">
                                            <label className="text-white label-search" htmlFor='Keyword'>Destination / Trip Code</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-map-marker-alt"></i></div>
                                                </div>
                                                <input type="text" name="destination" defaultValue="" className="form-control rounded-0" placeholder="Destination / Trip Code" style={{ fontSize: '14px' }} />
                                            </div>
                                        </div>
                                        <div className="col col-search">
                                            <label className="text-white label-search" htmlFor="departure">Departure Month</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                </div>
                                                <select name="departure" className="form-control rounded-0" id="departure" style={{ fontSize: '14px' }}>
                                                    <option defaultValue={''}>Any Month</option>
                                                    <option defaultValue={'2022-09'}>September 2022</option>
                                                    <option defaultValue={'2022-10'}>October 2022</option>
                                                    <option defaultValue={'2022-11'}>November 2022</option>
                                                    <option defaultValue={'2022-12'}>December 2022</option>
                                                    <option defaultValue={'2023-01'}>January 2023</option>
                                                    <option defaultValue={'2023-02'}>February 2023</option>
                                                    <option defaultValue={'2023-03'}>March 2023</option>
                                                    <option defaultValue={'2023-04'}>April 2023</option>
                                                    <option defaultValue={'2023-05'}>May 2023</option>
                                                    <option defaultValue={'2023-06'}>June 2023</option>
                                                    <option defaultValue={'2023-07'}>July 2023</option>
                                                    <option defaultValue={'2023-08'}>August 2023</option>
                                                    <option defaultValue={'2023-09'}>September 2023</option>
                                                    <option defaultValue={'2023-10'}>October 2023</option>
                                                    <option defaultValue={'2023-11'}>November 2023</option>
                                                    <option defaultValue={'2023-12'}>December 2023</option>
                                                    <option defaultValue={'2024-01'}>January 2024</option>
                                                    <option defaultValue={'2024-02'}>February 2024</option>
                                                    <option defaultValue={'2024-03'}>March 2024</option>
                                                    <option defaultValue={'2024-04'}>April 2024</option>
                                                    <option defaultValue={'2024-05'}>May 2024</option>
                                                    <option defaultValue={'2024-06'}>June 2024</option>
                                                    <option defaultValue={'2024-09'}>September 2024</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col col-search">
                                            <label className="text-white label-search" htmlFor="duration">Duration</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                </div>
                                                <select name="duration" id="" className="form-control rounded-0" style={{ fontSize: '14px' }}>
                                                    <option defaultValue={''}>Any Duration</option>
                                                    <option defaultValue={'1;10'}>1 - 10 Days</option>
                                                    <option defaultValue={'10;15'}>10 - 15 Days</option>
                                                    <option defaultValue={'15;20'}>15 - 20 Days</option>
                                                    <option defaultValue={'20;30'}>20 - 30 Days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col col-search">
                                            <label className="text-white label-search">Country</label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="fas fa-location-arrow"></i></div>
                                                </div>
                                                <select name="location" className="form-control" id="" style={{ fontSize: '14px' }}>
                                                    <option defaultValue={''}>Select Country</option>
                                                    <option defaultValue={'460;thailand'}>Thailand</option>
                                                    <option defaultValue={'468;south-korea'}>South Korea</option>
                                                    <option defaultValue={'482;india'}>India</option>
                                                    <option defaultValue={'484;china'}>China</option>
                                                    <option defaultValue={'485;japan'}>Japan</option>
                                                    <option defaultValue={'487;vietnam'}>Vietnam</option>
                                                    <option defaultValue={'489;multi-country'}>Multi-Country</option>
                                                    <option defaultValue={'497;sri-lanka'}>Sri Lanka</option>
                                                    <option defaultValue={'503;myanmar'}>Myanmar</option>
                                                    <option defaultValue={'504;nepal'}>Nepal</option>
                                                    <option defaultValue={'508;bhutan'}>Bhutan</option>
                                                    <option defaultValue={'512;mongolia'}>Mongolia</option>
                                                    <option defaultValue={'525;mekong-delta'}>Mekong Delta</option>
                                                    <option defaultValue={'543;borneo-&amp;-malaysia'}>Borneo &amp; Malaysia</option>
                                                    <option defaultValue={'544;laos'}>Laos</option>
                                                    <option defaultValue={'545;tibet'}>Tibet</option>
                                                    <option defaultValue={'573;cambodia'}>Cambodia</option>
                                                    <option defaultValue={'817;maldives'}>Maldives</option>
                                                    <option defaultValue={'572;portugal'}>Portugal</option>
                                                    <option defaultValue={'576;australia'}>Australia</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row mt-2">
                                        <div className="col-md-2 ml-auto p-3" style={{ float: 'right' }}>
                                            <button type="submit" className="btn btn-maucam btn-block font-weight-bold" style={{ padding: '0.35rem .75rem', marginBottom: '1rem' }}>Find</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="hot-page2-alp-con">
                        <div className="col-md-3 hot-page2-alp-con-left">
                            <div className="filter-l2 py-2 px-3 text-white rounded sticky-top" style={{ backgroundColor: '#495966' }}>
                                <div className="panel-group search-4-col content-form-sl2" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-default pb-2">
                                        <div className="panel-heading " role="tab" id="headingTwo11">
                                            <h5 className="panel-title border-bottom position-relative">
                                                <a className="collapsed text-white" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo11" aria-expanded="true" aria-controls="collapseTwo11">Search by keywords</a> </h5>
                                        </div>
                                        <div id="collapseTwo11" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingTwo11">
                                            <div className="input-group py-2">

                                                <input type="text" className="form-control rounded-0 searchinput" name="search" defaultValue="" id="searchinput1" />
                                                <div className="input-group-prepend">
                                                    <input type="button" className="btn btn-maucam" defaultValue="GO" style={{ fontSize: '14px' }} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="panel panel-default pb-2">
                                        <div className="panel-heading " role="tab" id="headingOne">
                                            <h5 className="panel-title border-bottom position-relative"> <a className="collapsed text-white" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> By Price</a></h5>
                                        </div>
                                        <div id="collapseOne" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
                                            <div className="panel-body pb-2">
                                                <div className="py-2" style={{ fontSize: '12px' }}>
                                                    <span className="irs irs--round js-irs-0 irs-with-grid">
                                                        <span className="irs"><span className="irs-line" tabIndex="0"></span>
                                                            <span className="irs-min" style={{ visibility: 'hidden' }}>$0</span>
                                                            <span className="irs-max" style={{ visibility: 'hidden' }}>$16 620</span>
                                                            <span className="irs-from" style={{ visibility: 'visible', left: '-0.353834%' }}>$0</span>
                                                            <span className="irs-to" style={{ visibility: 'visible', left: '81.0293%' }}>$16 620</span>
                                                            <span className="irs-single" style={{ visibility: 'hidden', left: '31.9612%' }}>$0 — $16 620</span>
                                                        </span>
                                                        <span className="irs-grid" style={{ width: '89.2377%', left: '5.28117%' }}>
                                                            <span className="irs-grid-pol" style={{ left: '0%' }}></span>
                                                            <span className="irs-grid-text js-grid-text-0" style={{ left: '0%', marginLeft: '-2.96735%' }}>0</span>
                                                            <span className="irs-grid-pol small" style={{ left: '20%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '15%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '10%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '5%' }}></span>
                                                            <span className="irs-grid-pol" style={{ left: '25%' }}></span>
                                                            <span className="irs-grid-text js-grid-text-1" style={{ left: '45%', visibility: 'visible', marginLeft: '-8.6428%' }}>4 155</span>
                                                            <span className="irs-grid-pol small" style={{ left: '45%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '40%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '35%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '30%' }}></span>
                                                            <span className="irs-grid-pol" style={{ left: '50%' }}></span>
                                                            <span className="irs-grid-text js-grid-text-2" style={{ left: '50%', visibility: 'visible', marginLeft: '-8.6428%' }}>8 310</span>
                                                            <span className="irs-grid-pol small" style={{ left: '70%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '65%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '60%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '55%' }}></span>
                                                            <span className="irs-grid-pol" style={{ left: '75%' }}></span>
                                                            <span className="irs-grid-text js-grid-text-3" style={{ left: '75%', visibility: 'visible', marginLeft: '-10.2613%' }}>12 465</span>
                                                            <span className="irs-grid-pol small" style={{ left: '95%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '90%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '85%' }}></span>
                                                            <span className="irs-grid-pol small" style={{ left: '80%' }}></span>
                                                            <span className="irs-grid-pol" style={{ left: '100%' }}></span>
                                                            <span className="irs-grid-text js-grid-text-4" style={{ left: '100%', marginLeft: '-10.2613%' }}>16 620</span>
                                                        </span>
                                                        <span className="irs-bar" style={{ left: '5.38117%', width: '89.2377%' }}></span>
                                                        <span className="irs-shadow shadow-from" style={{ display: 'none' }}></span>
                                                        <span className="irs-shadow shadow-to" style={{ display: 'none' }}></span>
                                                        <span className="irs-handle from" style={{ left: '0%' }}><i></i><i></i><i></i></span>
                                                        <span className="irs-handle to" style={{ left: '89.2377%' }}><i></i><i></i><i></i></span>
                                                    </span>
                                                    <input type="text" className="js-range-price irs-hidden-input" name="my_range" defaultValue="" tabIndex="-1" readOnly="" />
                                                    <input type="hidden" id="hidden_minimum_price" defaultValue="" />
                                                    <input type="hidden" id="hidden_maximum_price" defaultValue="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default pb-2">
                                        <div className="panel-heading " role="tab" id="heading4">
                                            <h5 className="panel-title border-bottom position-relative"> <a className="collapsed text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse41" aria-expanded="true" aria-controls="collapse41">Duration</a></h5>
                                        </div>
                                        <div id="collapse41" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="heading41">
                                            <div className="panel-body py-2">

                                                <select className="form-control duration1" id="duration1" name="duration">
                                                    <option defaultValue="">Any Duration</option>
                                                    <option defaultValue="1;10">1 - 10 Days</option>
                                                    <option defaultValue="10;15">10 - 15 Days</option>
                                                    <option defaultValue="15;20">15 - 20 Days</option>
                                                    <option defaultValue="20;30">20 - 30 Days</option>
                                                </select>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel panel-default pb-2">
                                        <div className="panel-heading " role="tab" id="heading4">
                                            <h5 className="panel-title border-bottom position-relative">
                                                <a className="collapsed text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse421" aria-expanded="true" aria-controls="collapse421">Depature Month</a></h5>
                                        </div>
                                        <div id="collapse421" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="heading421">
                                            <div className="panel-body py-2">
                                                <select name="departure" className="form-control" id="departure1" style={{ fontSize: '14px' }}>
                                                    <option defaultValue="">Any Month</option>
                                                    <option defaultValue="2022-09">September 2022</option>
                                                    <option defaultValue="2022-10">October 2022</option>
                                                    <option defaultValue="2022-11">November 2022</option>
                                                    <option defaultValue="2022-12">December 2022</option>
                                                    <option defaultValue="2023-01">January 2023</option>
                                                    <option defaultValue="2023-02">February 2023</option>
                                                    <option defaultValue="2023-03">March 2023</option>
                                                    <option defaultValue="2023-04">April 2023</option>
                                                    <option defaultValue="2023-05">May 2023</option>
                                                    <option defaultValue="2023-06">June 2023</option>
                                                    <option defaultValue="2023-07">July 2023</option>
                                                    <option defaultValue="2023-08">August 2023</option>
                                                    <option defaultValue="2023-09">September 2023</option>
                                                    <option defaultValue="2023-10">October 2023</option>
                                                    <option defaultValue="2023-11">November 2023</option>
                                                    <option defaultValue="2023-12">December 2023</option>
                                                    <option defaultValue="2024-01">January 2024</option>
                                                    <option defaultValue="2024-02">February 2024</option>
                                                    <option defaultValue="2024-03">March 2024</option>
                                                    <option defaultValue="2024-04">April 2024</option>
                                                    <option defaultValue="2024-05">May 2024</option>
                                                    <option defaultValue="2024-06">June 2024</option>
                                                    <option defaultValue="2024-09">September 2024</option>
                                                </select>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel panel-default pb-2">
                                        <div className="panel-heading " role="tab" id="heading4">
                                            <h5 className="panel-title border-bottom position-relative">
                                                <a className="collapsed text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse42" aria-expanded="true" aria-controls="collapse42">Country</a></h5>
                                        </div>
                                        <div id="collapse42" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="heading42">
                                            <div className="panel-body py-2">
                                                <select name="location" className="form-control" id="location1">
                                                    <option defaultValue="">Select Country</option>
                                                    <option defaultValue="460;thailand">Thailand</option>
                                                    <option defaultValue="468;south-korea">South Korea</option>
                                                    <option defaultValue="482;india">India</option>
                                                    <option defaultValue="484;china">China</option>
                                                    <option defaultValue="485;japan">Japan</option>
                                                    <option defaultValue="487;vietnam">Vietnam</option>
                                                    <option defaultValue="489;multi-country">Multi-Country</option>
                                                    <option defaultValue="497;sri-lanka">Sri Lanka</option>
                                                    <option defaultValue="503;myanmar">Myanmar</option>
                                                    <option defaultValue="504;nepal">Nepal</option>
                                                    <option defaultValue="508;bhutan">Bhutan</option>
                                                    <option defaultValue="512;mongolia">Mongolia</option>
                                                    <option defaultValue="525;mekong-delta">Mekong Delta</option>
                                                    <option defaultValue="543;borneo-&amp;-malaysia">Borneo &amp; Malaysia</option>
                                                    <option defaultValue="544;laos">Laos</option>
                                                    <option defaultValue="545;tibet">Tibet</option>
                                                    <option defaultValue="573;cambodia">Cambodia</option>
                                                    <option defaultValue="817;maldives">Maldives</option>
                                                    <option defaultValue="572;portugal">Portugal</option>
                                                    <option defaultValue="576;australia">Australia</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 hot-page2-alp-con-right">
                            <div className="hot-page2-alp-con-right-1">
                                <div className="row">
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.5</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home1.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Swiss, Paris &amp; Italy</h5></NavLink>
                                                <p>Home to numerous lakes, villages and high peaks of the ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$650</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.3</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home2.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Incredible India</h5></NavLink>
                                                <p>India may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$420</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.0</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home3.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Awesome Dubai: Action Packed</h5></NavLink>
                                                <p>Explore Dubai in all its glory with Yatra's 'Go Dubai.'  ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$720</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.8</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home4.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Amazing London and Edinburgh</h5></NavLink>
                                                <p>London may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$860</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.4</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home5.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Rio De Janeiro</h5></NavLink>
                                                <p>Brazil may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$480</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.2</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home6.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Roatan, Bay Islands</h5></NavLink>
                                                <p>India may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$1200</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.9</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home7.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Venice Holiday Packages</h5></NavLink>
                                                <p>Honeymoon calls for an utterly romantic and very ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$1350</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.5</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home1.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Swiss, Paris &amp; Italy</h5></NavLink>
                                                <p>Home to numerous lakes, villages and high peaks of the ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$650</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.3</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home2.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Incredible India</h5></NavLink>
                                                <p>India may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$420</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.0</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home3.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Awesome Dubai: Action Packed</h5></NavLink>
                                                <p>Explore Dubai in all its glory with Yatra's 'Go Dubai.'  ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$720</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.8</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home4.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Amazing London and Edinburgh</h5></NavLink>
                                                <p>London may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$860</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.4</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home5.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Rio De Janeiro</h5></NavLink>
                                                <p>Brazil may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$480</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.2</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home6.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Roatan, Bay Islands</h5></NavLink>
                                                <p>India may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$1200</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.9</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home7.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Venice Holiday Packages</h5></NavLink>
                                                <p>Honeymoon calls for an utterly romantic and very ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$1350</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.5</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home1.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Swiss, Paris &amp; Italy</h5></NavLink>
                                                <p>Home to numerous lakes, villages and high peaks of the ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$650</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.3</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home2.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Incredible India</h5></NavLink>
                                                <p>India may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$420</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.0</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home3.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Awesome Dubai: Action Packed</h5></NavLink>
                                                <p>Explore Dubai in all its glory with Yatra's 'Go Dubai.'  ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$720</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.8</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home4.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Amazing London and Edinburgh</h5></NavLink>
                                                <p>London may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$860</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.4</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home5.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Rio De Janeiro</h5></NavLink>
                                                <p>Brazil may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$480</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.2</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home6.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Roatan, Bay Islands</h5></NavLink>
                                                <p>India may not be the most ancient city in the world, but is surely one ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$1200</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 hot-page2-alp-r-list">
                                        <div className="col-md-12 hot-page2-alp-r-list-re-sp">
                                            <NavLink to="">
                                                <div className="hotel-list-score">4.9</div>
                                                <div className="hot-page2-hli-1"> <img src="../listing/home7.jpg" alt="" /> </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="trav-list-bod">
                                                <NavLink to="/detail"><h5>Venice Holiday Packages</h5></NavLink>
                                                <p>Honeymoon calls for an utterly romantic and very ...</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="hot-page2-alp-ri-p3 tour-alp-ri-p3">
                                                <div className="hot-page2-alp-r-hot-page-rat">25%Off</div> <span className="hot-list-p3-1">Prices Starting</span> <span className="hot-list-p3-2">$1350</span><span className="hot-list-p3-4">
                                                    <NavLink to="/detail" className="hot-page2-alp-quot-btn">Book Now</NavLink>
                                                </span> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Index;