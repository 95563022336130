import React from 'react';
import { NavLink } from 'react-router-dom';

const TopTour = () => {
    return (
        <section>
            <div className="rows pad-bot-redu tb-space">
                <div className="container">
                    <div className="spe-title">
                        <h2>Top <span>Tour Packages</span></h2>
                        <div className="title-line">
                            <div className="tl-1"></div>
                            <div className="tl-2"></div>
                            <div className="tl-3"></div>
                        </div>
                        <p>World's leading tour and travels Booking website,Over 30,000 packages worldwide.</p>
                    </div>
                    <div>
                        <div className="col-md-4 col-sm-6 col-xs-12 b_packages wow slideInUp animated hoverShine" data-wow-duration="0.5s" style={{ visibility: "visible" }}>
                            <div className="band"> <img src="../band.png" alt="" /> </div>
                            <div className="v_place_img"> <figure><img src="https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg" alt="Tour Booking" title="Tour Booking" /></figure> </div>
                            <div className="b_pack rows d-flex">
                                <div className="col-md-8 col-sm-8">
                                    <h4><NavLink to="/detail">Rio de Janeiro<span className="v_pl_name">(Brazil)</span></NavLink></h4>
                                </div>
                                <div className="col-md-4 col-sm-4 pack_icon">
                                    <ul>
                                        <li>
                                            <NavLink to="#"><img src="../clock.png" alt="Date" title="Tour Timing" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../info.png" alt="Details" title="View more details" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../price.png" alt="Price" title="Price" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../map.png" alt="Location" title="Location" /> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 b_packages wow fadeInUp animated hoverShine" data-wow-duration="0.7s" style={{ visibility: "visible" }}>
                            <div className="band"> <img src="../band1.png" alt="" /> </div>
                            <div className="v_place_img"> <figure><img src="https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg" alt="Tour Booking" title="Tour Booking" /></figure> </div>
                            <div className="b_pack rows d-flex">
                                <div className="col-md-8 col-sm-8">
                                    <h4><NavLink to="/detail">Paris<span className="v_pl_name">(England)</span></NavLink></h4>
                                </div>
                                <div className="col-md-4 col-sm-4 pack_icon">
                                    <ul>
                                        <li>
                                            <NavLink to="#"><img src="../clock.png" alt="Date" title="Tour Timing" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../info.png" alt="Details" title="View more details" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../price.png" alt="Price" title="Price" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../map.png" alt="Location" title="Location" /> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 b_packages wow fadeInUp animated hoverShine" data-wow-duration="0.9s" style={{ visibility: "visible" }}>
                            <div className="v_place_img"><figure><img src="https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg" alt="Tour Booking" title="Tour Booking" /></figure> </div>
                            <div className="b_pack rows d-flex">
                                <div className="col-md-8 col-sm-8">
                                    <h4><NavLink to="/detail">South India<span className="v_pl_name">(India)</span></NavLink></h4>
                                </div>
                                <div className="col-md-4 col-sm-4 pack_icon">
                                    <ul>
                                        <li>
                                            <NavLink to="#"><img src="../clock.png" alt="Date" title="Tour Timing" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../info.png" alt="Details" title="View more details" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../price.png" alt="Price" title="Price" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../map.png" alt="Location" title="Location" /> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 b_packages wow fadeInUp animated hoverShine" data-wow-duration="1.1s" style={{ visibility: "visible" }}>
                            <div className="v_place_img"><figure><img src="https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg" alt="Tour Booking" title="Tour Booking" /></figure> </div>
                            <div className="b_pack rows d-flex">
                                <div className="col-md-8 col-sm-8">
                                    <h4><NavLink to="/detail">The Great Wall<span className="v_pl_name">(China)</span></NavLink></h4>
                                </div>
                                <div className="col-md-4 col-sm-4 pack_icon">
                                    <ul>
                                        <li>
                                            <NavLink to="#"><img src="../clock.png" alt="Date" title="Tour Timing" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../info.png" alt="Details" title="View more details" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../price.png" alt="Price" title="Price" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../map.png" alt="Location" title="Location" /> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 b_packages wow fadeInUp animated hoverShine" data-wow-duration="1.3s" style={{ visibility: "visible" }}>
                            <div className="v_place_img"><figure><img src="https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg" alt="Tour Booking" title="Tour Booking" /></figure> </div>
                            <div className="b_pack rows d-flex">
                                <div className="col-md-8 col-sm-8">
                                    <h4><NavLink to="/detail">Nail Island<span className="v_pl_name">(Andaman)</span></NavLink></h4>
                                </div>
                                <div className="col-md-4 col-sm-4 pack_icon">
                                    <ul>
                                        <li>
                                            <NavLink to="#"><img src="../clock.png" alt="Date" title="Tour Timing" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../info.png" alt="Details" title="View more details" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../price.png" alt="Price" title="Price" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../map.png" alt="Location" title="Location" /> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 b_packages wow fadeInUp animated hoverShine" data-wow-duration="1.5s" style={{ visibility: "visible" }}>
                            <div className="v_place_img"><figure><img src="https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg" alt="Tour Booking" title="Tour Booking" /></figure> </div>
                            <div className="b_pack rows d-flex">
                                <div className="col-md-8 col-sm-8">
                                    <h4><NavLink to="/detail">Mauritius<span className="v_pl_name">(Indiana)</span></NavLink></h4>
                                </div>
                                <div className="col-md-4 col-sm-4 pack_icon">
                                    <ul>
                                        <li>
                                            <NavLink to="#"><img src="../clock.png" alt="Date" title="Tour Timing" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../info.png" alt="Details" title="View more details" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../price.png" alt="Price" title="Price" /> </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#"><img src="../map.png" alt="Location" title="Location" /> </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopTour;