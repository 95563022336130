import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Sales = () => {
    return (
        <section>
            <div className="offer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="offer-l">
                                <span className="ol-1"></span>
                                <span className="ol-2">
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                </span>
                                <span className="ol-4">Standardized Budget Rooms</span>
                                <span className="ol-3"></span>
                                <span className="ol-5">$99/-</span>
                                <ul>
                                    <li className="wow fadeInUp animated" data-wow-duration="0.5s" style={{ visibility: "visible" }}>
                                        <NavLink to="#!" className="waves-effect waves-light btn-large offer-btn"><img src="../dis1.png" alt="" />
                                        </NavLink><span>Free WiFi</span>
                                    </li>
                                    <li className="wow fadeInUp animated" data-wow-duration="0.7s" style={{ visibility: "visible" }}>
                                        <NavLink to="#!" className="waves-effect waves-light btn-large offer-btn"><img src="../dis2.png" alt="" /> </NavLink><span>Breakfast</span>
                                    </li>
                                    <li className="wow fadeInUp animated" data-wow-duration="0.9s" style={{ visibility: "visible" }}>
                                        <NavLink to="#!" className="waves-effect waves-light btn-large offer-btn"><img src="../dis3.png" alt="" /> </NavLink><span>Pool</span>
                                    </li>
                                    <li className="wow fadeInUp animated" data-wow-duration="1.1s" style={{ visibility: "visible" }}>
                                        <NavLink to="#!" className="waves-effect waves-light btn-large offer-btn"><img src="../dis4.png" alt="" /> </NavLink><span>Television</span>
                                    </li>
                                    <li className="wow fadeInUp animated" data-wow-duration="1.3s" style={{ visibility: "visible" }}>
                                        <NavLink to="#!" className="waves-effect waves-light btn-large offer-btn"><img src="../dis5.png" alt="" /> </NavLink><span>GYM</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="offer-r">
                                <div className="or-1"> <span className="or-11">go</span> <span className="or-12">Stays</span> </div>
                                <div className="or-2"> <span className="or-21">Get</span> <span className="or-22">70%</span> <span className="or-23">Off</span> <span className="or-24">use code: AGHZ43AKD0</span> <span className="or-25"></span> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sales;