import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from '../components/ScrollToTop';

import Footer from '../components/Footer';
import Footer1 from '../components/Footer1';
import Footer2 from '../components/Footer2';
import Header from '../components/Header';
import MenuTop from '../components/MenuTop';

import HomeRouter from '../router/HomeRouter';
import AboutRouter from '../router/AboutRouter';
import PackagesRouter from './PackagesRouter';
import DetailRouter from './DetailRouter';
import IconLeft from '../components/IconLeft';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <MenuTop />
        <div className="main-content">
          <ScrollToTop>
            <Routes>
              <Route exact={true} path="/" element={<HomeRouter />} />
              <Route path="/about" element={<AboutRouter />} />
              <Route path="/packages" element={<PackagesRouter />} />
              <Route path="/detail" element={<DetailRouter />} />
            </Routes>
          </ScrollToTop>
        </div>
        <Footer />
        <Footer1 />
        <Footer2 />
        <IconLeft />
      </div>
    </BrowserRouter>
  );
};

export default AppRouter;