import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuTop = () => {
    return (
        <div className="top-logo affix-top" data-spy="affix" data-offset-top="250">
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <div className="wed-logo">
                            <NavLink to="/"><img src="../logotest.png" alt="" /></NavLink>
                        </div>
                        <div className="main-menu">
                            <ul>
                                <li><NavLink to="/">Home</NavLink>
                                </li>
                                <li className="about-menu">
                                    <NavLink to="#" className="mm-arr">Tours</NavLink>
                                    <div className="mm-pos">
                                        <div className="about-mm m-menu">
                                            <div className="m-menu-inn">
                                                <div className="mm1-com mm1-s1" style={{ width: "33.33%" }}>
                                                    <div className="ed-course-in">
                                                        <NavLink className="course-overlay menu-about" to="/packages">
                                                            <img src="https://i1-dulich.vnecdn.net/2021/07/16/2-1626444940.jpg?w=0&h=0&q=100&dpr=2&fit=crop&s=GdpJnWaNQO_98jsWySh9cA" alt="" />
                                                            <span>Popular Package</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                                <div className="mm1-com mm1-s2" style={{ width: "33.33%" }}>
                                                    <p>Want to change the world? At Berkeley we’re doing just that. When you join the Golden Bear community, you’re part of an institution that shifts the global conversation every single day.</p>
                                                    <NavLink to="all-package.html" className="mm-r-m-btn">Read more</NavLink>
                                                </div>
                                                <div className="mm1-com mm1-s3" style={{ width: "33.33%" }}>
                                                    <ul>
                                                        <li><NavLink to="/packages">All Tour Packages</NavLink></li>
                                                        <li><NavLink to="#">Family Package</NavLink></li>
                                                        <li><NavLink to="#">Honeymoon Package</NavLink></li>
                                                        <li><NavLink to="#">Group Package</NavLink></li>
                                                        <li><NavLink to="#">WeekEnd Package</NavLink></li>
                                                        <li><NavLink to="#">Regular Package</NavLink></li>
                                                        <li><NavLink to="#">Custom Package</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="cr-menu">
                                    <NavLink to="#" className="mm-arr">Cruises</NavLink>
                                    <div className="mm-pos">
                                        <div className="cr-mm m-menu">
                                            <div className="m-menu-inn">
                                                <div className="mm1-com mm1-s1" style={{ width: "33.33%" }}>
                                                    <div className="ed-course-in">
                                                        <NavLink className="course-overlay menu-about" to="/packages">
                                                            <img src="https://i1-dulich.vnecdn.net/2021/07/16/2-1626444940.jpg?w=0&h=0&q=100&dpr=2&fit=crop&s=GdpJnWaNQO_98jsWySh9cA" alt="" />
                                                            <span>Hot Cruises</span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                                <div className="mm1-com mm1-s2" style={{ width: "33.33%" }}>
                                                    <p>Want to change the world? At Berkeley we’re doing just that. When you join the Golden Bear community, you’re part of an institution that shifts the global conversation every single day.</p>
                                                    <NavLink to="all-package.html" className="mm-r-m-btn">Read more</NavLink>
                                                </div>
                                                <div className="mm1-com mm1-s3" style={{ width: "33.33%" }}>
                                                    <ul>
                                                        <li><NavLink to="#">All Cruises</NavLink></li>
                                                        <li><NavLink to="#">Europe Cruises</NavLink></li>
                                                        <li><NavLink to="#">Asia Cruises</NavLink></li>
                                                        <li><NavLink to="#">America Cruises</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="admi-menu">
                                    <NavLink to="#" className="mm-arr">Sightseeing</NavLink>
                                    <div className="mm-pos">
                                        <div className="admi-mm m-menu">
                                            <div className="m-menu-inn">
                                                <div className="mm2-com mm1-com mm1-s1">
                                                    <div className="ed-course-in">
                                                        <NavLink className="course-overlay" to="#">
                                                            <img src="https://i1-dulich.vnecdn.net/2021/07/16/2-1626444940.jpg?w=0&h=0&q=100&dpr=2&fit=crop&s=GdpJnWaNQO_98jsWySh9cA" alt="" />
                                                            <span>Sightseeing - 1</span>
                                                        </NavLink>
                                                    </div>
                                                    <p>Donec lacus libero, rutrum ac sollicitudin sed, mattis non eros. Vestibulum congue nec eros quis lacinia. Mauris non tincidunt lectus. Nulla mollis, orci vitae accumsan rhoncus.</p>
                                                    <NavLink to="#" className="mm-r-m-btn">Read more</NavLink>
                                                </div>
                                                <div className="mm2-com mm1-com mm1-s1">
                                                    <div className="ed-course-in">
                                                        <NavLink className="course-overlay" to="#">
                                                            <img src="https://i1-dulich.vnecdn.net/2021/07/16/2-1626444940.jpg?w=0&h=0&q=100&dpr=2&fit=crop&s=GdpJnWaNQO_98jsWySh9cA" alt="" />
                                                            <span>Sightseeing - 2</span>
                                                        </NavLink>
                                                    </div>
                                                    <p>Donec lacus libero, rutrum ac sollicitudin sed, mattis non eros. Vestibulum congue nec eros quis lacinia. Mauris non tincidunt lectus. Nulla mollis, orci vitae accumsan rhoncus.</p>
                                                    <NavLink to="#" className="mm-r-m-btn">Read more</NavLink>
                                                </div>
                                                <div className="mm2-com mm1-com mm1-s1">
                                                    <div className="ed-course-in">
                                                        <NavLink className="course-overlay" to="#">
                                                            <img src="https://i1-dulich.vnecdn.net/2021/07/16/2-1626444940.jpg?w=0&h=0&q=100&dpr=2&fit=crop&s=GdpJnWaNQO_98jsWySh9cA" alt="" />
                                                            <span>Sightseeing - 3</span>
                                                        </NavLink>
                                                    </div>
                                                    <p>Donec lacus libero, rutrum ac sollicitudin sed, mattis non eros. Vestibulum congue nec eros quis lacinia. Mauris non tincidunt lectus. Nulla mollis, orci vitae accumsan rhoncus.</p>
                                                    <NavLink to="#" className="mm-r-m-btn">Read more</NavLink>
                                                </div>
                                                <div className="mm2-com mm1-com mm1-s1">
                                                    <div className="ed-course-in">
                                                        <NavLink className="course-overlay" to="#">
                                                            <img src="https://i1-dulich.vnecdn.net/2021/07/16/2-1626444940.jpg?w=0&h=0&q=100&dpr=2&fit=crop&s=GdpJnWaNQO_98jsWySh9cA" alt="" />
                                                            <span>Sightseeing - 4</span>
                                                        </NavLink>
                                                    </div>
                                                    <p>Donec lacus libero, rutrum ac sollicitudin sed, mattis non eros. Vestibulum congue nec eros quis lacinia. Mauris non tincidunt lectus. Nulla mollis, orci vitae accumsan rhoncus.</p>
                                                    <NavLink to="#" className="mm-r-m-btn">Read more</NavLink>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li><NavLink to="#">Hotels</NavLink></li>
                                <li><NavLink to="/testimonials">Testimonials</NavLink></li>
                                <li><NavLink to="#">Contact us</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuTop;