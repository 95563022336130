import React from 'react';
import { NavLink } from 'react-router-dom';
import Iframe from 'react-iframe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faClock, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGooglePlusG, faTwitter, faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Carousel } from 'react-carousel-minimal';

const Detail = () => {
    const data = [
        {
            image: "../gallery/t1.jpg",
            caption: "San Francisco"
        },
        {
            image: "../gallery/t2.jpg",
            caption: "Scotland"
        },
        {
            image: "../gallery/t3.jpg",
            caption: "Darjeeling"
        },
        {
            image: "../gallery/t4.jpg",
            caption: "San Francisco"
        },
        {
            image: "../gallery/t5.jpg",
            caption: "Scotland"
        },
        {
            image: "../gallery/t6.jpg",
            caption: "Darjeeling"
        },
        {
            image: "../gallery/t7.jpg",
            caption: "San Francisco"
        },
        {
            image: "../gallery/t8.jpg",
            caption: "Scotland"
        },
        {
            image: "../gallery/t9.jpg",
            caption: "Darjeeling"
        }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    return (
        <section>
            <div className="rows inn-page-bg com-colo">
                <div className="container inn-page-con-bg tb-space">
                    <div className="col-md-9">
                        <div className="tour_head">
                            <h2>The Best of Brazil &amp; Argentina
                                <span className="tour_star">
                                    <FontAwesomeIcon icon={faStar} style={{ marginLeft: "10px", color: "#FF9800" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FF9800" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FF9800" }} />
                                    <FontAwesomeIcon icon={faStar} style={{ color: "#FF9800" }} />
                                    <FontAwesomeIcon icon={faStarHalfAlt} style={{ color: "#FF9800" }} />
                                </span>
                                <span className="tour_rat">4.5</span>
                            </h2>
                        </div>
                        <div className="tour_head1">
                            <h3>Description</h3>
                            <p>Discover two of South America’s greatest cities, Rio de Janeiro and Buenos Aires, at a leisurely pace. A major highlight on this journey is a visit to Iguassu Falls in between your two city stays. It truly is one of the most spectacular sights on Earth. See the impressive falls from both the Brazilian and Argentine sides.</p>
                            <p>Brazil’s view takes you through clouds of mist and the opportunity to see these 275 falls, spanning nearly two miles! Argentina’s side allows you to walk along the boardwalk network and embark on a jungle train through the forest for unforgettable views. Hear the deafening roar and admire the brilliant rainbows created by the clouds of spray, and take in the majesty of this wonder of the world. From vibrant cities to scenic beauty, this vacation to Rio de Janeiro, Iguassu Falls, and Buenos Aires will leave you with vacation memories you’ll cherish for life.</p>
                        </div>
                        <div className="tour_head1 hotel-book-room">
                            <h3>Photo Gallery</h3>
                            <Carousel
                                data={data}
                                time={1500}
                                width="850px"
                                height="450px"
                                captionStyle={captionStyle}
                                captionPosition="bottom"
                                automatic={true}
                                dots={true}
                                pauseIconColor="white"
                                pauseIconSize="40px"
                                slideBackgroundColor="transparent"
                                slideImageFit="cover"
                                thumbnails={true}
                                thumbnailWidth="100px"
                                style={{
                                    textAlign: "center",
                                    maxWidth: "850px",
                                    maxHeight: "500px",
                                }}
                            />
                        </div>
                        <div className="tour_head1 tout-map map-container">
                            <h3>Location</h3>
                            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6290415.157581651!2d-93.99661009218904!3d39.661150926343694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880b2d386f6e2619%3A0x7f15825064115956!2sIllinois%2C+USA!5e0!3m2!1sen!2sin!4v1467884030780" />
                        </div>
                        <div className="tour_head1">
                            <h3>About The Tour</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Places covered</th>
                                        <th className="event-res">Inclusions</th>
                                        <th className="event-res">Exclusions</th>
                                        <th>Event Date</th>
                                    </tr>
                                    <tr>
                                        <td>Rio De Janeiro ,Brazil</td>
                                        <td className="event-res">Accommodation</td>
                                        <td className="event-res">Return Airfare &amp; Taxes</td>
                                        <td>Nov 12, 2017</td>
                                    </tr>
                                    <tr>
                                        <td>Iguassu Falls </td>
                                        <td className="event-res">8 Breakfast, 3 Dinners</td>
                                        <td className="event-res">Arrival &amp; Departure transfers</td>
                                        <td>Nov 14, 2017</td>
                                    </tr>
                                    <tr>
                                        <td>Peru,Lima </td>
                                        <td className="event-res">First-className Travel</td>
                                        <td className="event-res">travel insurance</td>
                                        <td>Nov 16, 2017</td>
                                    </tr>
                                    <tr>
                                        <td>Cusco &amp; Buenos Aires </td>
                                        <td className="event-res">Free Sightseeing</td>
                                        <td className="event-res">Service tax of 4.50%</td>
                                        <td>Nov 18, 2017</td>
                                    </tr>
                                </tbody></table>
                        </div>
                        <div className="tour_head1 l-info-pack-days days">
                            <h3>Detailed Day Wise Itinerary</h3>
                            <ul>
                                <li className="l-info-pack-plac"> <FontAwesomeIcon icon={faClock} />
                                    <h4><span>Day : 1</span> Arrival and Evening Dhow Cruise</h4>
                                    <p>Arrive at the airport and transfer to hotel. Check-in time at the hotel will be at 2:00 PM. In the evening, enjoy a tasty dinner on the Dhow cruise. Later, head back to the hotel for a comfortable overnight stay.</p>
                                </li>
                                <li className="l-info-pack-plac"> <FontAwesomeIcon icon={faClock} />
                                    <h4><span>Day : 2</span> City Tour and Evening Free for Leisure</h4>
                                    <p>After breakfast, proceed for tour of Dubai city. Visit Jumeirah Mosque, World Trade Centre, Palaces and Dubai Museum. Enjoy your overnight stay at the hotel.In the evening, enjoy a tasty dinner on the Dhow cruise. Later, head back to the hotel for a comfortable overnight stay.</p>
                                </li>
                                <li className="l-info-pack-plac"> <FontAwesomeIcon icon={faClock} />
                                    <h4><span>Day : 3</span> Desert Safari with Dinner</h4>
                                    <p>Relish a yummy breakfast and later, proceed to explore the city on your own. Enjoy shopping at Mercato Shopping Mall, Dubai Mall and Wafi City. In the evening, enjoy the desert safari experience and belly dance performance. Relish a mouth-watering barbecue dinner and enjoy staying overnight in Dubai.</p>
                                </li>
                                <li className="l-info-pack-plac"> <FontAwesomeIcon icon={faClock} />
                                    <h4><span>Day : 4</span> Day at leisure</h4>
                                    <p>Savour a satiating breakfast and relax for a while. Day Free for leisure. Overnight stay will be arranged in Dubai. In the evening, enjoy a tasty dinner on the Dhow cruise. Later, head back to the hotel for a comfortable overnight stay.</p>
                                </li>
                                <li className="l-info-pack-plac"> <FontAwesomeIcon icon={faClock} />
                                    <h4><span>Day : 5</span> Departure</h4>
                                    <p>Fill your tummy with yummy breakfast and relax for a while. Later, check out from the hotel and proceed for your onward journey.In the evening, enjoy a tasty dinner on the Dhow cruise. Later, head back to the hotel for a comfortable overnight stay.</p>
                                </li>
                            </ul>
                        </div>
                        <div className="dir-rat">
                            <div className="dir-rat-inn dir-rat-title">
                                <h3>Write Your Rating Here</h3>
                                <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text</p>

                            </div>
                            <div className="dir-rat-inn">
                                <form className="dir-rat-form">
                                    <div className="form-group col-md-6 pad-left-o">
                                        <input type="text" className="form-control" id="email11" placeholder="Enter Name" /> </div>
                                    <div className="form-group col-md-6 pad-left-o">
                                        <input type="number" className="form-control" id="email12" placeholder="Enter Mobile" /> </div>
                                    <div className="form-group col-md-6 pad-left-o">
                                        <input type="email" className="form-control" id="email13" placeholder="Enter Email id" /> </div>
                                    <div className="form-group col-md-6 pad-left-o">
                                        <input type="text" className="form-control" id="email14" placeholder="Enter your City" /> </div>
                                    <div className="form-group col-md-12 pad-left-o">
                                        <textarea placeholder="Write your message"></textarea>
                                    </div>
                                    <div className="form-group col-md-12 pad-left-o">
                                        <input type="submit" value="SUBMIT" className="link-btn" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 tour_r">
                        <div className="tour_right tour_offer">
                            <div className="band1">
                                <img src="../gallery/offer.png" alt="" />
                            </div>
                            <p>Special Offer</p>
                            <h4>$500<span className="n-td">
                                <span className="n-td-1">$800</span>
                            </span>
                            </h4> <NavLink to="#" className="link-btn">Book Now</NavLink>
                        </div>
                        <div className="tour_right tour_incl tour-ri-com">
                            <h3>Trip Information</h3>
                            <ul>
                                <li>Location : Rio,Brazil</li>
                                <li>Arrival Date: Nov 12, 2017</li>
                                <li>Departure Date: Nov 21, 2017</li>
                                <li>Free Sightseeing &amp; Hotel</li>
                            </ul>
                        </div>
                        <div className="tour_right head_right tour_social tour-ri-com">
                            <h3>Share This Package</h3>
                            <ul>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faFacebookF} style={{ backgroundColor: "#3b5998" }} /></NavLink> </li>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faGooglePlusG} style={{ backgroundColor: "#dd4b39" }} /></NavLink> </li>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faTwitter} /></NavLink> </li>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faLinkedinIn} style={{ backgroundColor: "#0077b5" }} /></NavLink> </li>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faWhatsapp} /></NavLink> </li>
                            </ul>
                        </div>
                        <div className="tour_right head_right tour_help tour-ri-com">
                            <h3>Help &amp; Support</h3>
                            <div className="tour_help_1">
                                <h4 className="tour_help_1_call">Call Us Now</h4>
                                <h4><FontAwesomeIcon icon={faPhone} /> 10-800-123-000</h4> </div>
                        </div>
                        <div className="tour_right tour_rela tour-ri-com">
                            <h3>Popular Packages</h3>
                            <div className="tour_rela_1"> <img src="../gallery/related1.png" alt="" />
                                <h4>Dubai 7Days / 6Nights</h4>
                                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p> <NavLink to="#" className="link-btn">View this Package</NavLink>
                            </div>
                            <div className="tour_rela_1"> <img src="../gallery/related2.png" alt="" />
                                <h4>Mauritius 4Days / 3Nights</h4>
                                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p> <NavLink to="#" className="link-btn">View this Package</NavLink>
                            </div>
                            <div className="tour_rela_1"> <img src="../gallery/related3.png" alt="" />
                                <h4>India 14Days / 13Nights</h4>
                                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p> <NavLink to="#" className="link-btn">View this Package</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Detail;