import React from 'react';
import { NavLink } from 'react-router-dom';

const Book = () => {
    return (
        <section>
            <div className="rows banner_book" id="inner-page-title">
                <div className="container">
                    <div className="banner_book_1">
                        <ul>
                            <li className="dl1">Location : Rio,Brazil</li>
                            <li className="dl2">Price : $500</li>
                            <li className="dl3">Duration : 8 Nights/ 9 Days</li>
                            <li className="dl4"><NavLink to="/detail">Book Now</NavLink> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Book;