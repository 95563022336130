import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <section>
            <div className="rows">
                <div className="footer1 home_title tb-space">
                    <div className="pla1 container">
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="disco1 disco" style={{ height: "100%" }}>
                                <h3>30%<span>OFF</span></h3>
                                <h5 style={{ color: '#fff' }}>Eiffel Tower,Rome</h5>
                                <p>valid only for 24th Dec</p> <NavLink to="/">Book Now</NavLink> </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="disco1 disco" style={{ height: "100%" }}>
                                <h3>42%<span>OFF</span></h3>
                                <h5 style={{ color: '#fff' }}>Colosseum,Burj Al Arab</h5>
                                <p>valid only for 18th Nov</p> <NavLink to="/">Book Now</NavLink> </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 foot-spec footer_places">
                            <h5><span>Most Popular Vacations</span></h5>
                            <ul>
                                <li><NavLink to="#">Angkor Wat</NavLink> </li>
                                <li><NavLink to="#">Buckingham Palace</NavLink> </li>
                                <li><NavLink to="#">High Line</NavLink> </li>
                                <li><NavLink to="#">Sagrada Família</NavLink> </li>
                                <li><NavLink to="#">Statue of Liberty </NavLink> </li>
                                <li><NavLink to="#">Notre Dame de Paris</NavLink> </li>
                                <li><NavLink to="#">Taj Mahal</NavLink> </li>
                                <li><NavLink to="#">The Louvre</NavLink> </li>
                                <li><NavLink to="#">Tate Modern, London</NavLink> </li>
                                <li><NavLink to="#">Gothic Quarter</NavLink> </li>
                                <li><NavLink to="#">Table Mountain</NavLink> </li>
                                <li><NavLink to="#">Bayon</NavLink> </li>
                                <li><NavLink to="#">Great Wall of China</NavLink> </li>
                                <li><NavLink to="#">Hermitage Museum</NavLink> </li>
                                <li><NavLink to="#">Yellowstone</NavLink> </li>
                                <li><NavLink to="#">Musée d'Orsay</NavLink> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;