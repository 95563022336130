import React from 'react';
// import { BrowserRouter } from 'react-router-dom';
import TourzSearch from '../components/Home/TourzSearch';
import TopTour from '../components/Home/TopTour';
import Popular from '../components/Home/Popular';
import Book from '../components/Home/Book';
import Sales from '../components/Home/Sales';
import Events from '../components/Home/Events';
import Sightseeing from '../components/Home/Sightseeing';
import Qoute from '../components/Home/Qoute';
import Qoute2 from '../components/Home/Qoute2';

const HomeRouter = () => {
    return (
        <div>
            <TourzSearch />
            <TopTour />
            <Popular />
            <Book />
            <Sales />
            <Events />
            <Sightseeing />
            <Qoute />
            <Qoute2 />
        </div>
    );
};

export default HomeRouter;