/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGooglePlusG, faTwitter } from "@fortawesome/free-brands-svg-icons";


const Header = () => {
    return (
        <div className="ed-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ed-com-t1-left">
                            <ul>
                                <li>
                                    <NavLink to="#"><marquee behavior='scroll' direction='left'>Contact: 000, Asia Online Tours</marquee></NavLink>
                                </li>
                                <li>
                                    <NavLink to="#"><marquee behavior='scroll' direction='left'>Phone: +000-0000-0000</marquee></NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="ed-com-t1-right">
                            <ul>
                                <li><NavLink to="#">Sign In</NavLink>
                                </li>
                                <li><NavLink to="#" style={{ backgroundColor: '#2d5d73 !important' }}>Sign Up</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="ed-com-t1-social">
                            <ul>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faFacebookF} style={{ padding: "12px 5px 0 5px", color: "#3b5998" }} /></NavLink>
                                </li>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faGooglePlusG} style={{ padding: "12px 5px 0 5px", color: "#dd4b39" }} /></NavLink>
                                </li>
                                <li><NavLink to="#"><FontAwesomeIcon icon={faTwitter} style={{ padding: "12px 5px 0 5px", color: "#39A9DD" }} /></NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;