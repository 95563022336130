import React from 'react';

const Testimonials = () => {
    return (
        <section>
            <div className="rows inn-page-bg com-colo">
                <div className="container tb-space inn-page-con-bg pad-bot-redu" id="inner-page-title">
                    <div className="spe-title">
                        <h2>Customer <span>Testimonials</span></h2>
                        <div className="title-line">
                            <div className="tl-1"></div>
                            <div className="tl-2"></div>
                            <div className="tl-3"></div>
                        </div>
                        <p>World's leading tour and travels Booking website,Over 30,000 packages worldwide.</p>
                    </div>
                    <div className="p_testimonial">
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Praesent rutrum convallis nisl vitae aliquam. Suspendisse non quam vehicula, tincidunt nibh at, porta orci. Maecenas egestas</p> <address>Illinois, USA</address> </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-tesi">
                                <div className="col-md-3 col-sm-3"> <img src="./../testi_img.png" alt="" /> </div>
                                <div className="col-md-9 col-sm-9">
                                    <h4>Best tour package forever</h4>
                                    <div><span className="tour_star"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star-half-o" aria-hidden="true"></i></span> </div>
                                    <p>Suspendisse tortor lacus, sodales nec elementum id, lobortis in arcu. Praesent sit amet purus mi. Praesent rutrum convallis.</p> <address>Perth, Australia</address> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;