import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG, faTwitter, faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Footer1 = () => {
    return (
        <section>
            <div className="rows">
                <div className="footer">
                    <div className="container">
                        <div className="foot-sec2">
                            <div>
                                <div className="row">
                                    <div className="col-sm-3 foot-spec foot-com">
                                        <h5><span>Holiday Tour &amp; Travels</span></h5>
                                        <p>World's leading tour and travels Booking website,Over 30,000 packages worldwide.</p>
                                    </div>
                                    <div className="col-sm-3 foot-spec foot-com">
                                        <h5><span>Address &amp; Contact Info</span></h5>
                                        <p>28800 Orchard Lake Road, Suite 180 Farmington Hills, U.S.A. Landmark : Next To Airport</p>
                                        <p> <span className="strong">Phone: </span> <span className="highlighted">+101-1231-1231</span> </p>
                                    </div>
                                    <div className="col-sm-3 col-md-3 foot-spec foot-com">
                                        <h5><span>SUPPORT &amp; HELP</span></h5>
                                        <ul className="two-columns">
                                            <li> <NavLink to="/about">About Us</NavLink> </li>
                                            <li> <NavLink to="#">FAQ</NavLink> </li>
                                            <li> <NavLink to="#">Feedbacks</NavLink> </li>
                                            <li> <NavLink to="#">Blog </NavLink> </li>
                                            <li> <NavLink to="#">Use Cases</NavLink> </li>
                                            <li> <NavLink to="#">Advertise us</NavLink> </li>
                                            <li> <NavLink to="#">Discount</NavLink> </li>
                                            <li> <NavLink to="#">Vacations</NavLink> </li>
                                            <li> <NavLink to="#">Branding Offers </NavLink> </li>
                                            <li> <NavLink to="#">Contact Us</NavLink> </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-3 foot-social foot-spec foot-com">
                                        <h5><span>Follow with us</span></h5>
                                        <p>Join the thousands of other There are many variations of passages of Lorem Ipsum available</p>
                                        <ul>
                                            <li><NavLink to="#"><FontAwesomeIcon icon={faFacebookF} style={{ color: "#3b5998" }} /></NavLink> </li>
                                            <li><NavLink to="#"><FontAwesomeIcon icon={faGooglePlusG} style={{ color: "#dd4b39" }} /></NavLink> </li>
                                            <li><NavLink to="#"><FontAwesomeIcon icon={faTwitter} style={{ color: "#39A9DD" }} /></NavLink> </li>
                                            <li><NavLink to="#"><FontAwesomeIcon icon={faLinkedinIn} style={{ color: "#0077b5" }} /></NavLink> </li>
                                            <li><NavLink to="#"><FontAwesomeIcon icon={faWhatsapp} style={{ color: "#00B51E" }}/></NavLink> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer1;