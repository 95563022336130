import React from 'react';
// import { BrowserRouter } from 'react-router-dom';
import Banner from '../components/Detail/Banner';
import Detail from '../components/Detail/Detail';
import Book from '../components/Detail/Book';

const DetailRouter = () => {
    return (
        <div>
            <Banner />
            <Book />
            <Detail />
        </div>
    );
};

export default DetailRouter;