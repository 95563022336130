import React from 'react';
// import { BrowserRouter } from 'react-router-dom';
import Tips from '../components/About/Tips';
import Banner from '../components/Packages/Banner';
import Index from '../components/Packages/Index';

const PackagesRouter = () => {
    return (
        <div>
            <Banner />
            <Index />
            <Tips />
        </div>
    );
};

export default PackagesRouter;