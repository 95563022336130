import React from 'react';
import { NavLink } from 'react-router-dom';

const Popular = () => {
    return (
        <section>
            <div className="rows tb-space pad-top-o pad-bot-redu">
                <div className="container">
                    <div className="spe-title">
                        <h2>Popular <span>Cities</span> </h2>
                        <div className="title-line">
                            <div className="tl-1"></div>
                            <div className="tl-2"></div>
                            <div className="tl-3"></div>
                        </div>
                        <p>World's leading Hotel Booking website,Over 30,000 Hotel rooms worldwide. Book Hotel rooms and enjoy your holidays with distinctive experience</p>
                    </div>
                    <div className="col-md-6">
                        <NavLink to="/detail">
                            <div className="tour-mig-like-com">
                                <div className="tour-mig-lc-img">
                                    <figure className="snip0019">
                                        <img src="../listing/home.jpg" alt="" />
                                        <figcaption>
                                            <div><h5>Europe</h5></div>
                                            <div><p>Starting from $2400</p></div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3">
                        <NavLink to="/detail">
                            <div className="tour-mig-like-com">
                                <div className="tour-mig-lc-img">
                                    <figure className="snip0019">
                                        <img src="../listing/home3.jpg" alt="" />
                                        <figcaption>
                                            <div><h5>Dubai</h5></div>
                                            <div><p>Starting from $2400</p></div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3">
                        <NavLink to="/detail">
                            <div className="tour-mig-like-com">
                                <div className="tour-mig-lc-img">
                                    <figure className="snip0019">
                                        <img src="../listing/home2.jpg" alt="" />
                                        <figcaption>
                                            <div><h5>India</h5></div>
                                            <div><p>Starting from $2400</p></div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3">
                        <NavLink to="/detail">
                            <div className="tour-mig-like-com">
                                <div className="tour-mig-lc-img">
                                    <figure className="snip0019">
                                        <img src="../listing/home1.jpg" alt="" />
                                        <figcaption>
                                            <div><h5>USA</h5></div>
                                            <div><p>Starting from $2400</p></div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3">
                        <NavLink to="/detail">
                            <div className="tour-mig-like-com">
                                <div className="tour-mig-lc-img">
                                    <figure className="snip0019">
                                        <img src="../listing/home4.jpg" alt="" />
                                        <figcaption>
                                            <div><h5>Lodon</h5></div>
                                            <div><p>Starting from $2400</p></div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Popular;