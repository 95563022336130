import React from 'react';
// import { BrowserRouter } from 'react-router-dom';
import Banner from '../components/About/Banner';
import Testimonials from '../components/About/Testimonials';
import Tips from '../components/About/Tips';

const AboutRouter = () => {
    return (
        <div>
            <Banner />
            <Testimonials />
            <Tips />
        </div>
    );
};

export default AboutRouter;