import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
    return (
        <section>
            <div className="rows inner_banner inner_banner_3">
                <div className="container">
                    <h2><span>Top Travel</span> Packages</h2>
                    <ul>
                        <li><NavLink exact={true} to="/">Home</NavLink> </li>
                        <li><FontAwesomeIcon icon={faAngleRight} /></li>
                        <li><NavLink to="/packages" className="bread-acti">Hotels &amp; Restaurants</NavLink> </li>
                    </ul>
                    <p>World's leading Hotel Booking website,Over 30,000 Hotel rooms worldwide. </p>
                </div>
            </div>
        </section>
    );
};

export default Banner;