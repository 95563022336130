import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Qoute2 = () => {
    return (
        <section>
            <div className="foot-mob-sec tb-space">
                <div className="rows container">
                    <div className="col-md-6 col-sm-6 col-xs-12 family"> <img src="../mobile.png" alt="" /> </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="foot-mob-app">
                            <h2 className="Blazing">Have you tried our mobile app?</h2>
                            <p>World's leading tour and travels Booking website,Over 30,000 packages worldwide. Book travel packages and enjoy your holidays with distinctive experience</p>
                            <ul>
                                <li><FontAwesomeIcon icon={faCheck} /> Easy Hotel Booking</li>
                                <li><FontAwesomeIcon icon={faCheck} /> Tour and Travel Packages</li>
                                <li><FontAwesomeIcon icon={faCheck} /> Package Reviews and Ratings</li>
                                <li><FontAwesomeIcon icon={faCheck} /> Manage your Bookings, Enquiry and Reviews</li>
                            </ul>
                            <NavLink to="#"><img src="../android.png" alt="" /> </NavLink>
                            <NavLink to="#"><img src="../apple.png" alt="" /> </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Qoute2;