import AppRouter from './router/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import './styles.css';
import './materialize.css';
import './animate.css';
import './mob.css';
import './bootstrap.css';
import './custom';
import React, { StrictMode }  from 'react';
import {createRoot} from 'react-dom/client';


// 👇️ IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// 👇️ if you use TypeScript, add non-null (!) assertion operator
// const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    <AppRouter />
  </StrictMode>,
);