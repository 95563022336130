import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

const Book = () => {
    return (
        <section>
            <div className="rows tb-space pad-top-o pad-bot-redu">
                <div className="container">
                    <div className="spe-title">
                        <h2>Hotels <span>booking open now! </span> </h2>
                        <div className="title-line">
                            <div className="tl-1"></div>
                            <div className="tl-2"></div>
                            <div className="tl-3"></div>
                        </div>
                        <p>World's leading Hotel Booking website,Over 30,000 Hotel rooms worldwide. Book Hotel rooms and enjoy your holidays with distinctive experience</p>
                    </div>
                    <div className="to-ho-hotel">
                        <div className="col-md-4">
                            <div className="to-ho-hotel-con">
                                <div className="to-ho-hotel-con-1">
                                    <div className="hot-page2-hli-3"> <img src="../hci1.png" alt="" /> </div>
                                    <div className="hom-hot-av-tic"> Available Tickets: 42 </div> <img src="../hotels/1.jpg" alt="" /> </div>
                                <div className="to-ho-hotel-con-23">
                                    <div className="to-ho-hotel-con-2">
                                        <NavLink to="/detail">
                                            <h4>GTC Grand Chola</h4>
                                        </NavLink>
                                    </div>
                                    <div className="to-ho-hotel-con-3">
                                        <ul>
                                            <li>City: illunois,united states
                                                <div className="dir-rat-star ho-hot-rat-star"> Rating:
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStarHalfAlt} style={{ color: "#f4364f" }} />
                                                </div>
                                            </li>
                                            <li><span className="ho-hot-pri-dis">$720</span><span className="ho-hot-pri">$420</span> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="to-ho-hotel-con">
                                <div className="to-ho-hotel-con-1">
                                    <div className="hot-page2-hli-3"> <img src="../hci1.png" alt="" /> </div>
                                    <div className="hom-hot-av-tic"> Available Tickets: 520 </div> <img src="../hotels/2.jpg" alt="" /> </div>
                                <div className="to-ho-hotel-con-23">
                                    <div className="to-ho-hotel-con-2">
                                        <NavLink to="/detail">
                                            <h4>Taaj Grand Resorts</h4>
                                        </NavLink>
                                    </div>
                                    <div className="to-ho-hotel-con-3">
                                        <ul>
                                            <li>City: illunois,united states
                                                <div className="dir-rat-star ho-hot-rat-star"> Rating:
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStarHalfAlt} style={{ color: "#f4364f" }} />
                                                </div>
                                            </li>
                                            <li><span className="ho-hot-pri-dis">$840</span><span className="ho-hot-pri">$540</span> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="to-ho-hotel-con">
                                <div className="to-ho-hotel-con-1">
                                    <div className="hot-page2-hli-3"> <img src="../hci1.png" alt="" /> </div>
                                    <div className="hom-hot-av-tic"> Available Tickets: 92 </div> <img src="../hotels/3.jpg" alt="" /> </div>
                                <div className="to-ho-hotel-con-23">
                                    <div className="to-ho-hotel-con-2">
                                        <NavLink to="/detail">
                                            <h4>Keep Grand Hotels</h4>
                                        </NavLink>
                                    </div>
                                    <div className="to-ho-hotel-con-3">
                                        <ul>
                                            <li>City: illunois,united states
                                                <div className="dir-rat-star ho-hot-rat-star"> Rating:
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStar} style={{ color: "#f4364f" }} />
                                                    <FontAwesomeIcon icon={faStarHalfAlt} style={{ color: "#f4364f" }} />
                                                </div>
                                            </li>
                                            <li><span className="ho-hot-pri-dis">$680</span><span className="ho-hot-pri">$380</span> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Book;